<template>
    <div class="operaDashboard">
        <div class="card-row">
            <div class="card-div">
                <div class="opera-title">关键运营数据</div>
                <div class="opera-data">
                    <el-descriptions class="opera-data-div" title="" :column="1" border>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    当前单量
                                </div>
                            </template>
                            {{ resultObject.dqdl }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    即将超时
                                </div>
                            </template>
                            {{ resultObject.jjcs }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    升级单量
                                </div>
                            </template>
                            {{ resultObject.sjdl }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    已超时
                                </div>
                            </template>
                            {{ resultObject.ycs }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    当月达标率
                                </div>
                            </template>
                            {{ resultObject.dydbl }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    当月升级率
                                </div>
                            </template>
                            {{ resultObject.dysjl }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div class="card-div">
                <div class="opera-title">数据统计</div>
                <div class="black-div">
                    <div class="first-div-list">
                        <div class="one-list">
                            <el-icon class="msg-box-icon">
                                <MessageBox />
                            </el-icon>
                            <div class="list-span">
                                <span class="list-span-tip">当日开单</span>
                                <span class="list-span-tip blue-span">{{ kgdsj.drkd }}</span>
                            </div>
                        </div>
                        <div class="one-list">
                            <el-icon class="msg-box-icon">
                                <DocumentChecked />
                            </el-icon>
                            <div class="list-span">
                                <span class="list-span-tip">当日关单</span>
                                <span class="list-span-tip blue-span">{{ kgdsj.drgd }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="first-div-list">
                        <div class="one-list">
                            <div class="list-span one-row-span">
                                <span class="list-span-tip font-size-big">月度关单量</span>
                                <span class="list-span-tip blue-span">{{ kgdsj.dykd }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="first-div-list">
                        <div class="one-list">
                            <div class="list-span one-row-span">
                                <span class="list-span-tip font-size-big">季度关单量</span>
                                <span class="list-span-tip blue-span">{{ kgdsj.jdgd }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-div">
                <div class="opera-title">服务器专项TOP7</div>
                <div class="service-div">
                    <div class="service-list">
                        <div class="service-title service-item">一级机房</div>
                        <div class="service-item" v-for="(item, index) in serviceList" :key="index">
                            <span>{{ item.xasix }}</span>
                        </div>
                    </div>
                    <div class="service-list">
                        <div class="service-title service-item">待处理服务器数量</div>
                        <div class="service-item" v-for="(item, index) in serviceList" :key="index">
                            <span>{{ item.yasix }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-card class="el-card-div" style="">
            <div style="font-size: 18px;font-weight: bolder;">超时工单反馈</div>
            <el-table :data="tableData" style=" height: 320px;overflow-y: scroll;">
                <el-table-column v-for="column in columns" :key="column.prop" :prop="column.prop"
                    :label="column.label"></el-table-column>
            </el-table>
            <el-pagination layout="prev, pager, next" :total="totalPage" :page-size="20"
                @current-change="handleCurrentChange" class="table-pagination" />
        </el-card>
        <el-card class="el-card-div" v-loading="loading2">
            <div class="check-box">
                <el-radio-group v-model="chat1" class="ml-4" @change="changChat1">
                    <el-radio value="month">月</el-radio>
                    <el-radio value="day">日</el-radio>
                </el-radio-group>
            </div>
            <div class="myChart1" ref="myChart1"></div> <!-- 服务器退役设备量趋势 -->
        </el-card>
        <div class="myChart3" ref="myChart3" v-loading="loading3"></div> <!--服务器退役设备量/及时率-->
    </div>

</template>

<script setup>
import { onMounted, ref, nextTick, computed } from 'vue'
import * as echarts from 'echarts'
import { get, post } from '../components/https';
import { MessageBox, DocumentChecked } from '@element-plus/icons-vue'

let serviceList = ref([])
let tableData = ref([]), totalPage = ref(0), currentPage = ref(1)
let columns = ref([
    // { prop: 'scene', label: '场景' },
    { prop: 'campus', label: '园区' },
    { prop: 'gdh', label: '工单' },
    { prop: 'csyy', label: '反馈信息' },
])
const resultObject = ref({
    dqdl: 0,
    jjcs: 0,
    sjdl: 0,
    ycs: 0,
    dydbl: 0,
    dysjl: 0,
})
const kgdsj = ref({
    jdkd: 0,
    drgd: 0,
    drkd: 0,
    dygd: 0

})
let loading1 = ref(true), loading2 = ref(true), loading3 = ref(true), loading4 = ref(true)
let chat1 = ref('month')
const myChart1 = ref(null), myChart3 = ref(null)
onMounted(() => {
    // 加载数据
    loadingData1()
    loadingData11()
    loadingData21(currentPage.value)
    loadingData22(chat1.value)
    loadingData3()
})
// 关键运营数据
const loadingData1 = () => {
    get('chart/tpcgjyysj').then((res) => {
        if (res.errorCode == 0) {
            resultObject.value = res.result
            loading1.value = false
        }
    })
}
const loadingData11 = () => {
    get('chart/tpckgdzx').then((res) => {
        if (res.errorCode == 0) {
            kgdsj.value = res.result.kgdsj
            serviceList.value = res.result.fwqzx.slice(0, 7)
        }
    })
}
const handleCurrentChange = (page) => {
    currentPage.value = page
    loadingData21(page)
}
const loadingData21 = (page) => {
    get('chart/tpcdycsgd?currentPage=' + page).then((res) => {
        if (res.errorCode == 0) {
            tableData.value = res.result.content
            totalPage.value = res.result.totalElements
            // currentPage.value = res.result.page
            loading1.value = false
        }
    })
}
const loadingData22 = (type) => {
    get('chart/tcpsblqs?type=' + type).then((res) => {
        if (res.errorCode == 0) {
            let resultObject = {
                xasix: [], // 用于存储xasix值的数组  
                yasix: [],  
                maxValue: 0,
            };
            // 遍历原始数组并填充结果对象  
            res.result.forEach(item => {
                resultObject.xasix.push(item.xasix);
                resultObject.yasix.push(item.yasix);
            });
            loading2.value = false
            resultObject.maxValue = Math.max(...resultObject.yasix)
            loadingChat1(resultObject)
        }
    })
}
const loadingData3 = () => {
    get('chart/tcpjslhz').then((res) => {
        if (res.errorCode == 0) {
            let resultObject3 = {
                yjjf: [], //一级机房名称
                bq: [],//柱状图数据
                bqjsl: [],//折线图数据
                ty: [], //退役
                tyjsl: [],//退役折线图数据
                maxValue: 0,
            };

            // 遍历原始数组并填充结果对象  
            res.result.forEach(item => {
                resultObject3.yjjf.push(item.x);
                resultObject3.bq.push(item.bq);
                resultObject3.bqjsl.push(item.bqjsl);
                resultObject3.ty.push(item.ty);
                resultObject3.tyjsl.push(item.tyjsl);
            });

            loading3.value = false
            resultObject3.zzt = [...resultObject3.bq, ...resultObject3.ty]
            resultObject3.maxValue = Math.max(...resultObject3.zzt)
            loadingChat3(resultObject3)
        }
    })
}
const changChat1 = (val) => {
    nextTick(() => {
        loadingData22(val)
    })
}

const loadingChat1 = (data) => {
    let chart2 = data
    if (myChart1.value) {
        let myChart = echarts.init(myChart1.value);
        let option = {
            title: {
                text: 'TPC工单量'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#1E9FFF',
                '#009688',
                '#FFB980',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.xasix,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue / 10,
                    min: 0,
                    max: chart2.maxValue,
                    position: 'left',
                }
            ],
            series: [
                {
                    name: '工单量',
                    data: chart2.yasix,
                    type: 'line',
                    // itemStyle: {
                    //     normal: {
                    //         label: {
                    //             formatter: function (data) {
                    //                 return data.value
                    //             },
                    //             show: true,
                    //             position: 'left'
                    //         },
                    //     }
                    // },
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

const loadingChat3 = (data) => {
    let chart2 = data
    if (myChart3.value) {
        let myChart = echarts.init(myChart3.value);
        let option = {
            title: {
                text: '各机房TPC工单量/及时率/升级率'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#5470c6',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.yjjf,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue / 5,
                    min: 0,
                    max: chart2.maxValue,
                    position: 'left',
                },
                {
                    type: 'value',

                    position: 'right',
                    interval: 10,
                    min: 0,
                    max: 100,
                    alignTicks: true,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: '{value}%'
                    }
                }
            ],
            series: [
                // {
                //     name: '退役设备量',
                //     data: chart2.ty,
                //     type: 'bar',
                //     barWidth: 30,
                //     yAxisIndex: 0,
                //     label: { normal: { show: true, position: 'inside' } }

                // },
                {
                    name: 'TPC工单量',
                    data: chart2.bq,
                    type: 'bar',
                    barWidth: 30,
                    yAxisIndex: 0,
                    label: { normal: { show: true, position: 'inside' } }

                },
                {
                    name: '及时率',
                    data: chart2.bqjsl,
                    type: 'line',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value + '%'
                                },
                                show: true,
                                position: 'right'
                            },
                        }
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '升级率',
                    data: chart2.tyjsl,
                    type: 'line',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value + '%'
                                },
                                show: true,
                                position: 'right'
                            },
                        }
                    },
                    yAxisIndex: 1,
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

</script>
<style scoped>
.el-card-div {
    width: 48%;
    display: inline-block;
    margin: 0px 8px 8px 8px;
}

.card-row {
    display: flex;
}

.card-div {
    flex: 1;
    margin: 0px 40px 8px 8px;
}

/* .card-div:first-child {
    flex-basis: 20%;
}

.card-div:last-child {
    flex-basis: 10%;
} */

.myChart1,
.myChart2,
.myChart3,
.myChart4 {
    background-color: var(--el-bg-color-overlay);
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 0px 8px 8px 8px;
}

.myChart3,
.myChart4 {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    width: 98%;
}

.barStyl {
    border: 2px solid #0055E6;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.redColor {
    color: red;
    font-size: 14px;
    margin-left: 10px;
}

.opera-data {
    display: flex;
    width: 100%;
}

.opera-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.opera-data-div {
    flex: 1;
    border: 1px solid #DFDFE0;
}

.first-div-list {
    background-color: #4575C4;
    margin-bottom: 22px;
    display: flex;
    width: 100%;
}

.one-list {
    flex: 1;
    height: 66px;
    /* line-height: 50px; */
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.list-span {
    display: inline-block;
    color: #fff;
    text-align: center;
    margin-left: 10px;
}

.one-row-span {
    margin-left: 30%;
}

.one-row-span span.list-span-tip {
    display: inline-block;
    font-size: 20px;
}

.one-row-span span.list-span-tip.blue-span {
    font-size: 20px;
    margin-left: 20px;
}

span.list-span-tip {
    display: block;
    font-size: 14px;
}

span.list-span-tip.blue-span {
    color: #303133;
    font-size: 18px;
    font-weight: 600;
}

.msg-box-icon {
    color: #fff;
    margin-left: 25%;
    font-size: 30px;
}

.service-div {
    display: flex;
    border: 2px solid #AFABAB;
    padding: 2px;
}

.service-list {
    flex: 1;
    text-align: center;
    margin-left: 2px;
}

.service-title.service-item {
    height: 30px;
    line-height: 30px;
    background: #4575C4;
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
}

.service-item {
    background: #f2f2f2;
    margin-bottom: 3px;
    height: 24px;
}

.table-pagination {
    margin-bottom: 0;
}
</style>
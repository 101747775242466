<template>
    <div class="chatPage">
        <el-card class="el-card-div" v-loading="loading1">
            <div class="check-box">
                <el-radio-group v-model="chat1" class="ml-4" @change="changChat1">
                    <el-radio value="month">月</el-radio>
                    <el-radio value="day">日</el-radio>
                </el-radio-group>
            </div>
            <div class="myChart1" ref="myChart1"></div> <!-- 服务器退役设备量趋势 -->
        </el-card>
        <el-card class="el-card-div" v-loading="loading2">
            <div class="check-box">
                <el-radio-group v-model="chat2" class="ml-4" @change="changChat2">
                    <el-radio value="month">月</el-radio>
                    <el-radio value="day">日</el-radio>
                </el-radio-group>
            </div>
            <div class="myChart2" ref="myChart2"></div> <!-- 服务器搬迁设备量趋势 -->
        </el-card>
        <div class="barStyl">
            <span>当前及时率 {{ myChart5 }}%</span>
            <span class="redColor">退役搬迁SLA：80%</span>
        </div>
        <div class="myChart3" ref="myChart3"  v-loading="loading3"></div> <!--服务器退役设备量/及时率-->
        <div class="myChart4" ref="myChart4"  v-loading="loading4"></div> <!--服务器搬迁设备量/及时率-->
        
        <el-card shadow="never" header="" class="paddingClass" style="margin-top: 5px">
            <el-row>
                <el-col :span="6">
                    <el-statistic title="当月退役总单量(设备量)" :value="myChart6.tydy" />
                </el-col>
                <el-col :span="6">
                    <el-statistic title="当前超时未完成单量(设备量)" :value="myChart6.tydq" />
                </el-col>
                <el-col :span="6">
                    <el-statistic title="当月搬迁总单量(设备量)" :value="myChart6.bqdy" />

                </el-col>
                <el-col :span="6">
                    <el-statistic title="当前超时未完成单量(设备量)" :value="myChart6.bqdq" />

                </el-col>
            </el-row>
        </el-card>
    </div>

</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import * as echarts from 'echarts'
import { get, post } from '../components/https';

let loading1 = ref(true), loading2 = ref(true), loading3 = ref(true), loading4 = ref(true)
let chat1 = ref('month'), chat2 = ref('month')
const myChart1 = ref(null), myChart2 = ref(null),
    myChart3 = ref(null), myChart4 = ref(null),
    myChart5 = ref(null), myChart6 = ref({
        tydy: 0,//当月退役总单量/设备量(退役服务器)
        tydq: 0, //当前超时未完成单量/设备量 (退役服务器)
        bqdy: 0, //当月退役总单量/设备量(搬迁服务器)
        bqdq: 0
    })
onMounted(() => {
    // 加载数据
    loadingData1(chat1.value)
    loadingData2(chat2.value)
    loadingData3()
})
const loadingData1 = (type) => {
    get('chart/tysblqs?type=' + type).then((res) => {
        if (res.errorCode == 0) {
            let resultObject = {
                xasix: [], // 用于存储xasix值的数组  
                yasix: [],  // 用于存储yasix值的数组  
                maxValue: 0,
            };
            // 遍历原始数组并填充结果对象  
            res.result.forEach(item => {
                resultObject.xasix.push(item.xasix);
                resultObject.yasix.push(item.yasix);
            });
            loading1.value = false
            resultObject.maxValue = Math.max(...resultObject.yasix)
            loadingChat1(resultObject)
        }
    })
}
const loadingData2 = (type) => {
    get('chart/bqsblqs?type=' + type).then((res) => {
        if (res.errorCode == 0) {
            let resultObject = {
                xasix: [], // 用于存储xasix值的数组  
                yasix: [],  // 用于存储yasix值的数组  
                maxValue: 0,
            };
            // 遍历原始数组并填充结果对象  
            res.result.forEach(item => {
                resultObject.xasix.push(item.xasix);
                resultObject.yasix.push(item.yasix);
            });
            loading2.value = false
            resultObject.maxValue = Math.max(...resultObject.yasix)
            loadingChat2(resultObject)
        }
    })
}
const loadingData3 = () => {
    get('chart/jsl').then((res) => {
        if (res.errorCode == 0) {
            let resultObject3 = {
                yjjf: [], //一级机房名称
                zzt: [],//柱状图数据
                zxt: [],//折线图数据
                maxValue: 0,
            };
            let resultObject4 = {
                yjjf: [], //一级机房名称
                zzt: [],//柱状图数据
                zxt: [],//折线图数据
                maxValue: 0,
            };
            // 遍历原始数组并填充结果对象  
            res.result.ty.forEach(item => {
                resultObject3.yjjf.push(item.yjjf);
                resultObject3.zzt.push(item.zzt);
                resultObject3.zxt.push(item.zxt);
            });
            res.result.bq.forEach(item => {
                resultObject4.yjjf.push(item.yjjf);
                resultObject4.zzt.push(item.zzt);
                resultObject4.zxt.push(item.zxt);
            });
            loading3.value = false
            loading4.value = false
            resultObject3.maxValue = Math.max(...resultObject3.zzt)
            resultObject4.maxValue = Math.max(...resultObject4.zzt)
            loadingChat3(resultObject3)
            loadingChat4(resultObject4)
            myChart5.value = res.result.SLA
            myChart6.value = {
                tydy: res.result.tydy,
                tydq: res.result.tydq,
                bqdy: res.result.bqdy,
                bqdq: res.result.bqdq
            }
        }
    })
}
const changChat1 = (val) => {
    nextTick(() => {
        loadingData1(val)
    })
}
const changChat2 = (val) => {
    nextTick(() => {
        loadingData2(val)
    })
}
const loadingChat1 = (data) => {
    let chart2 = data
    if (myChart1.value) {
        let myChart = echarts.init(myChart1.value);
        let option = {
            title: {
                text: '服务器退役设备量趋势'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#1E9FFF',
                '#009688',
                '#FFB980',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.xasix,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue / 10,
                    min: 0,
                    max: chart2.maxValue,
                    position: 'left',
                }
            ],
            series: [
                {
                    name: '退役设备量',
                    data: chart2.yasix,
                    type: 'line',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value
                                },
                                show: true,
                                position: 'right'
                            },
                        }
                    },
                }
            ]
        };

        option && myChart.setOption(option);
    }
}
const loadingChat2 = (data) => {
    let chart2 = data
    if (myChart2.value) {
        let myChart = echarts.init(myChart2.value);
        let option = {
            title: {
                text: '服务器搬迁设备量趋势'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#1E9FFF',
                '#009688',
                '#FFB980',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.xasix,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue / 10,
                    min: 0,
                    max: chart2.maxValue,
                    position: 'left',
                }
            ],
            series: [
                {
                    name: '搬迁设备量',
                    data: chart2.yasix,
                    type: 'line',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value
                                },
                                show: true,
                                position: 'right'
                            },
                        }
                    },
                }
            ]
        };

        option && myChart.setOption(option);
    }
}
const loadingChat3 = (data) => {
    let chart2 = data
    if (myChart3.value) {
        let myChart = echarts.init(myChart3.value);
        let option = {
            title: {
                text: '服务器退役设备量/及时率'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#1E9FFF',
                '#009688',
                '#FFB980',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.yjjf,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue / 5,
                    min: 0,
                    max: chart2.maxValue,
                    position: 'left',
                },
                {
                    type: 'value',

                    position: 'right',
                    interval: 10,
                    min: 0,
                    max: 100,
                    alignTicks: true,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: '{value}%'
                    }
                }
            ],
            series: [
                {
                    name: '退役设备量',
                    data: chart2.zzt,
                    type: 'bar',
                    stack: 'a',
                    barWidth: 30,
                    yAxisIndex: 0,
                    label: { normal: { show: true, position: 'inside' } }

                },
                {
                    name: '及时率',
                    data: chart2.zxt,
                    type: 'line',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value + '%'
                                },
                                show: true,
                                position: 'right'
                            },
                        }
                    },
                    yAxisIndex: 1,
                }
            ]
        };

        option && myChart.setOption(option);
    }
}
const loadingChat4 = (data) => {
    let chart2 = data
    if (myChart4.value) {
        let myChart = echarts.init(myChart4.value);
        let option = {
            title: {
                text: '服务器搬迁设备量/及时率'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#1E9FFF',
                '#009688',
                '#FFB980',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.yjjf,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue / 5,
                    min: 0,
                    max: chart2.maxValue + 20,
                    position: 'left',
                },
                {
                    type: 'value',

                    position: 'right',
                    interval: 10,
                    min: 0,
                    max: 100,
                    alignTicks: true,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: '{value}%'
                    }
                }
            ],
            series: [
                {
                    name: '搬迁设备量',
                    data: chart2.zzt,
                    type: 'bar',
                    stack: 'a',
                    barWidth: 30,
                    yAxisIndex: 0,
                    label: { normal: { show: true, position: 'inside' } }

                },
                {
                    name: '及时率',
                    data: chart2.zxt,
                    type: 'line',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value + '%'
                                },
                                show: true,
                                position: 'right'
                            },
                        }
                    },
                    yAxisIndex: 1,
                }
            ]
        };

        option && myChart.setOption(option);
    }
}
</script>
<style scoped>
.el-card-div {
    width: 48%;
    display: inline-block;
    margin: 0px 8px 8px 8px;
}
.myChart1,
.myChart2,
.myChart3,
.myChart4{
    background-color: var(--el-bg-color-overlay);
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 0px 8px 8px 8px;
}
.myChart3, .myChart4 {
    box-shadow:0px 0px 12px rgba(0,0,0,0.12);
    width: 98%;
}
.barStyl {
    border: 2px solid #0055E6;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}
.redColor {
    color: red;
    font-size: 14px;
    margin-left: 10px;
}
</style>
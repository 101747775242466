<template>
    <div class="operaDashboard">
        <div class="card-row">
            <div class="card-div">
                <div class="opera-title">关键运营数据</div>
                <div class="opera-data">
                    <el-descriptions class="opera-data-div" title="" :column="1" border>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    当前PO单量
                                </div>
                            </template>
                            {{ resultObject.gjyysj.zdl }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    待办单量
                                </div>
                            </template>
                            {{ resultObject.gjyysj.dbdl }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    即将超时单量
                                </div>
                            </template>
                            {{ resultObject.gjyysj.jjcs }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    已超时单量
                                </div>
                            </template>
                            {{ resultObject.gjyysj.ycs }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template #label>
                                <div class="cell-item">
                                    当月达标率
                                </div>
                            </template>
                            {{ resultObject.gjyysj.dbl }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div class="card-div">
                <div class="opera-title">数据统计</div>
                <div class="black-div">
                    <div class="first-div-list">
                        <div class="one-list">
                            <el-icon class="msg-box-icon">
                                <MessageBox />
                            </el-icon>
                            <div class="list-span">
                                <span class="list-span-tip">当日开单</span>
                                <span class="list-span-tip blue-span">{{ resultObject.tjsj.drkd }}</span>
                            </div>
                        </div>
                        <div class="one-list">
                            <el-icon class="msg-box-icon">
                                <DocumentChecked />
                            </el-icon>
                            <div class="list-span">
                                <span class="list-span-tip">当日关单</span>
                                <span class="list-span-tip blue-span">{{ resultObject.tjsj.drgd }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="first-div-list">
                        <div class="one-list">
                            <div class="list-span one-row-span">
                                <span class="list-span-tip font-size-big">月度关单量</span>
                                <span class="list-span-tip blue-span">{{ resultObject.tjsj.ydgd }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="first-div-list">
                        <div class="one-list">
                            <div class="list-span one-row-span">
                                <span class="list-span-tip font-size-big">季度关单量</span>
                                <span class="list-span-tip blue-span">{{ resultObject.tjsj.jdgd }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-div">
                <div class="opera-title">PO单TOP5</div>
                <div class="service-div">
                    <div class="service-list">
                        <div class="service-title service-item">一级机房</div>
                        <div class="service-item" v-for="(item, index) in resultObject.potop5" :key="index">
                            <span>{{ item.xasix }}</span>
                        </div>
                    </div>
                    <div class="service-list">
                        <div class="service-title service-item">已接PO单量</div>
                        <div class="service-item" v-for="(item, index) in resultObject.potop5" :key="index">
                            <span>{{ item.yasix }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="myChart3" ref="myChart3" v-loading="loading3"></div> <!--个机房po单数/及时率-->
        
    </div>

</template>

<script setup>
import { onMounted, ref, nextTick, computed } from 'vue'
import * as echarts from 'echarts'
import { get, post } from '../components/https';
import { MessageBox, DocumentChecked } from '@element-plus/icons-vue'

let serviceList = ref([]), roomList = ref([])
let tableData = ref([]), totalPage = ref(0), currentPage = ref(1)

const resultObject = ref({
    gjyysj: {
        jjcs: 0,
        zdl: 0,
        dbdl: 0,
        ycs: 0,
        dbl: 0,
    },
    tjsj: {
        ydgd: 0,
        drgd: 0,
        jdgd: 0,
        drkd: 0,
    },
    potop5:[]
})

let loading1 = ref(true), loading2 = ref(true), loading3 = ref(true), loading4 = ref(true)
let chat1 = ref('month')
const myChart1 = ref(null), myChart3 = ref(null)
onMounted(() => {
    // 加载数据
    loadingData1()

    loadingData3()
    // loadingData4()
})
// 关键运营数据
const loadingData1 = () => {
    get('potickets/kbInfo').then((res) => {
        if (res.errorCode == 0) {
            resultObject.value = res.result
            loading1.value = false
        }
    })
}

const loadingData3 = () => {
    get('potickets/jsl').then((res) => {
        if (res.errorCode == 0) {
            let resultObject3 = {
                yjjf: [], //一级机房名称
                zdl:[],
                jsl: [],
                maxValue: 0,
            };

            // 遍历原始数组并填充结果对象  
            res.result.forEach(item => {
                resultObject3.yjjf.push(item.yjjf);
                resultObject3.zdl.push(item.zdl);
                resultObject3.jsl.push(item.jsl);
            });

            loading3.value = false
            resultObject3.maxValue = Math.max(...resultObject3.zdl)
            loadingChat3(resultObject3)
        }
    })
}

const loadingChat3 = (data) => {
    let chart2 = data
    if (myChart3.value) {
        let myChart = echarts.init(myChart3.value);
        let option = {
            title: {
                text: '各机房PO单量/及时率'
            },
            tooltip: {
                trigger: 'axis'
            },
            color: [
                '#5470c6',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
            ],
            legend: { top: '5' },
            grid: {
                left: '6%',
                right: '8%',
                bottom: '6%',
                containLabel: true
            },

            toolbox: {
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    saveAsImage: { show: true }
                }
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 40 // 倾斜20度
                },
                data: chart2.yjjf,
                boundaryGap: true,
            },
            yAxis: [
                {
                    type: 'value',
                    interval: chart2.maxValue/5,
                    min: 0,
                    max:chart2.maxValue,
                    position: 'left',
                },
                {
                    type: 'value',

                    position: 'right',
                    interval: 10,
                    min: 0,
                    max: 100,
                    alignTicks: true,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: '{value}%'
                    }
                }
            ],
            series: [
                {
                    name: '机房PO单量',
                    data: chart2.zdl,
                    type: 'bar',
                    barWidth: 30,
                    yAxisIndex: 0,
                    label: { normal: { show: true, position: 'inside' } }

                },
                {
                    name: '机房及时率',
                    data: chart2.jsl,
                    type: 'line',
                    position: 'right',
                    itemStyle: {
                        normal: {
                            label: {
                                formatter: function (data) {
                                    return data.value + '%'
                                },
                                show: true,
                            },
                        }
                    },
                    yAxisIndex: 1,
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

</script>
<style scoped>
.el-card-div {
    width: 48%;
    display: inline-block;
    margin: 0px 8px 8px 8px;
}

.card-row {
    display: flex;
}

.card-div {
    flex: 1;
    margin: 0px 8px 8px 8px;
}

.card-div:first-child {
    flex-basis: 20%;
}

.card-div:last-child {
    flex-basis: 10%;
}

.myChart1,
.myChart2,
.myChart3,
.myChart4 {
    background-color: var(--el-bg-color-overlay);
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 0px 8px 8px 8px;
}

.myChart3,
.myChart4 {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    width: 98%;
}

.barStyl {
    border: 2px solid #0055E6;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.redColor {
    color: red;
    font-size: 14px;
    margin-left: 10px;
}

.opera-data {
    display: flex;
    width: 100%;
}

.opera-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.opera-data-div {
    flex: 1;
    border: 1px solid #DFDFE0;
}

.first-div-list {
    background-color: #4575C4;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
}

.one-list {
    flex: 1;
    height: 61px;
    /* line-height: 50px; */
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.list-span {
    display: inline-block;
    color: #fff;
    text-align: center;
    margin-left: 10px;
}

.one-row-span {
    margin-left: 60px;
}

.one-row-span span.list-span-tip {
    display: inline-block;
    font-size: 20px;
}

.one-row-span span.list-span-tip.blue-span {
    font-size: 20px;
    margin-left: 20px;
}

span.list-span-tip {
    display: block;
    font-size: 14px;
}

span.list-span-tip.blue-span {
    color: #303133;
    font-size: 18px;
    font-weight: 600;
}

.msg-box-icon {
    color: #fff;
    margin-left: 30px;
    font-size: 30px;
}

.service-div {
    display: flex;
    border: 2px solid #AFABAB;
    padding: 2px;
}

.service-list {
    flex: 1;
    text-align: center;
    margin-left: 2px;
}

.service-title.service-item {
    height: 30px;
    line-height: 30px;
    background: #4575C4;
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
}

.service-item {
    background: #f2f2f2;
    margin-bottom: 3px;
    height: 24px;
}

.table-pagination {
    margin-bottom: 0;
}
</style>
<template>
    <div class="computer-room">
        <div class="computer-room-content">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-card class="statis-card">
                        <el-statistic title="裁撤资源人员数量" :value="spanData.userCount" class="statis-div"/>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card  class="statis-card">
                        <el-statistic title="网络资源人员数量" :value="spanData.resourceNum"  class="statis-div"/>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card  class="statis-card">
                        <el-statistic title="资产资源人员数量" :value="spanData.networkNum"  class="statis-div"/>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card  class="statis-card">
                        <el-statistic title="专项资源人员数量" :value="spanData.onlyworkNum"  class="statis-div"/>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-card>
                        <div ref="chart1" class="chartDiv"></div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card>
                        <div ref="chart2" class="chartDiv"></div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card>
                        <div ref="chart3" class="chartDiv"></div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card>
                        <div ref="chart4" class="chartDiv"></div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-card class="box-card">
                <el-form :inline="true" :model="formInline" class="form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.gjz"></el-input>
                    </el-form-item>
                    <el-row class="margin-style"><el-col>
                            <el-button @click="(formInline.gjz = '', getTicketList(currentPage))">重置</el-button>
                            <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                            <el-button @click="handleAdd">新增白名单</el-button>
                        </el-col></el-row>
                </el-form>

                <el-table ref="multipleTableRef" :data="tableData" row-key="id" class="tableStyl">
                    <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop"
                        :label="column.label" sortable />
                    <el-table-column fixed="right" label="操作" width="120">
                        <template #default="scope">
                            <el-button link type="primary" size="small" @click="handleDelete(scope.$index, scope.row)">
                                删除
                            </el-button>
                            <el-button link type="primary" size="small" @click="handleEdit(scope.$index, scope.row)">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
            </el-card>
        </div>

        <el-drawer v-model="dialog" :title="drawerTitle" class="demo-drawer">
            <div class="demo-drawer__content">
                <el-form :model="form">
                    <el-form-item label="身份证号" prop="idcard">
                        <el-input v-model="form.idcard"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="人员类型" prop="personnel">
                        <el-select v-model="form.personnel">
                            <el-option label="网络资源白名单" value="网络资源白名单" />
                            <el-option label="资源调度白名单" value="资源调度白名单" />
                            <el-option label="资源池白名单" value="资源池白名单" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账号" prop="account">
                        <el-input v-model="form.account"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="phone">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="区域" prop="region">
                        <el-select v-model="form.region">
                            <el-option label="华东" value="华东" />
                            <el-option label="华北" value="华北" />
                            <el-option label="华南" value="华南" />
                            <el-option label="华西" value="华西" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="城市" prop="city">
                        <el-input v-model="form.city"></el-input>
                    </el-form-item>
                    <el-form-item label="企微是否激活" prop="wechat">
                        <el-radio-group v-model="form.wechat">
                            <el-radio value="已激活">已激活</el-radio>
                            <el-radio value="未激活">未创建</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="区域主管" prop="manager">
                        <el-input v-model="form.manager"></el-input>
                    </el-form-item>
                    <el-form-item label="是否锁定" prop="locked">
                        <el-radio-group v-model="form.locked">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="子商" prop="subquotient">
                        <el-input v-model="form.subquotient"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="comment">
                        <el-input type="textarea" v-model="form.comment"></el-input>
                    </el-form-item>
                    <el-form-item label="是否在职" prop="zaizhi">
                        <el-radio-group v-model="form.zaizhi">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="专项" prop="zx">
                        <el-radio-group v-model="form.zx">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="裁撤" prop="cc">
                        <el-radio-group v-model="form.cc">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="资产" prop="zc">
                        <el-radio-group v-model="form.zc">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="网络" prop="wl">
                        <el-radio-group v-model="form.wl">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit">提交</el-button>
                        <el-button @click="dialog = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import * as echarts from 'echarts'
import { get, post } from '../components/https';

const drawerTitle = ref('')
const dialog = ref(false)
const form = ref({})
const spanData = ref({
    userCount: 0,
    resourceNum: 0,
    networkNum: 0,
    onlyworkNum: 0
})
const tableData = ref([])
const chart1 = ref(null), chart2 = ref(null), chart3 = ref(null), chart4 = ref(null)
const columns = [
    {
        prop: 'name',
        label: '姓名'
    },
    {
        prop: 'idcard',
        label: '身份证号'
    },
    
    // {
    //     prop: 'personnel',
    //     label: '人员类型'
    // },
    // {
    //     prop: 'account',
    //     label: '账号'
    // },
    {
        prop: 'phone',
        label: '联系方式'
    },
    // {
    //     prop: 'region',
    //     label: '区域'
    // },
    {
        prop: 'city',
        label: '城市'
    },
    {
        prop: 'wechat',
        label: '企微是否激活'
    },
    // {
    //     prop: 'manager',
    //     label: '区域主管'
    // },
    // {
    //     prop: 'locked',
    //     label: '是否锁定'
    // },
    {
        prop: 'subquotient',
        label: '子商'
    },
    {
        prop: 'zx',
        label: '专项'
    },
    {
        prop: 'cc',
        label: '裁撤'
    },
    {
        prop: 'zc',
        label: '资产'
    },
    {
        prop: 'wl',
        label: '网络'
    },
]
const formInline = ref({
    gjz: ''
})
onMounted(() => {
    columns.map(item => {
        form.value[item.prop] = ''
    })
    const chartData1 = [], chartData2 = [], chartData3 = [], chartData4 = []
    get('whitelist/info').then((res) => {
        if (res.errorCode == 0) {
            res.result.cc.map(item => {
                spanData.value.userCount += item.yasix
                chartData1.push({ name: item.xasix, value: item.yasix })
            })
            res.result.wl.map(item => {
                spanData.value.resourceNum += item.yasix
                chartData2.push({ name: item.xasix, value: item.yasix })
            })
            res.result.zc.map(item => {
                spanData.value.networkNum += item.yasix
                chartData3.push({ name: item.xasix, value: item.yasix })
            })
            res.result.zx.map(item => {
                spanData.value.onlyworkNum += item.yasix
                chartData4.push({ name: item.xasix, value: item.yasix })
            })
            // 加载数据
            loadingChat1(chartData1)
            loadingChat2(chartData2)
            loadingChat3(chartData3)
            loadingChat4(chartData4)
        }
    })
    getTicketList(currentPage.value)
})

const getTicketList = (currentPage) => {

    get('whitelist/list?currentPage=' + currentPage +'&pageSize='+ pageSize.value +'&gjz=' + formInline.value.gjz).then((res) => {
        if (res.errorCode == 0) {
            tableData.value = res.result.content
            totalData.value = res.result.totalElements

        }
    })
}

const loadingChat1 = (data) => {
    let chart2 = data
    if (chart1.value) {
        let myChart = echarts.init(chart1.value);
        let option = {
            title: {
                text: '裁撤人员区域分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart2,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

const loadingChat2 = (data) => {
    let chart1 = data
    if (chart2.value) {
        let myChart = echarts.init(chart2.value);
        let option = {
            title: {
                text: '网络人员区域分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart1,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

const loadingChat3 = (data) => {
    let chart2 = data
    if (chart3.value) {
        let myChart = echarts.init(chart3.value);
        let option = {
            title: {
                text: '资产人员区域分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart2,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

const loadingChat4 = (data) => {
    let chart2 = data
    if (chart4.value) {
        let myChart = echarts.init(chart4.value);
        let option = {
            title: {
                text: '专项人员区域分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart2,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const handleDelete = (index, row) => {
    get('whitelist/del?id=' + row.id).then((res) => {
        if (res.errorCode == 0) {
            tableData.value.splice(index, 1); // 从数组中移除该项
        }
    })
}

const handleEdit = (index, row) => {
    dialog.value = true
    form.value = { ...row }
    drawerTitle.value = '编辑'
}

const handleAdd = () => {
    dialog.value = true
    drawerTitle.value = '新增'
    form.value = {
        idcard: '',
        name: '',
        region: '',
        manager: '',
        locked: '',
        subquotient: '',
        comment: '',
        zaizhi: '',
    }
}

const handleSubmit = () => {
    if (drawerTitle.value == '新增') {
        post('whitelist/create', form.value).then((res) => {
            if (res.errorCode == 0) {
                getTicketList(currentPage.value)
                dialog.value = false
            }
        })
    } else {
        post('whitelist/update', form.value).then((res) => {
            if (res.errorCode == 0) {
                getTicketList(currentPage.value)
                dialog.value = false
            }
        })
    }
}
</script>

<style scoped>
.chartDiv {
    background-color: var(--el-bg-color-overlay);
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 0px 8px 8px 8px;
}

.tableStyl {
    margin-top: 20px;
}
.el-col {
    margin-bottom: 20px;
}
.statis-card {
    background: #F5F9FF;
}
>>>.statis-div .el-statistic__head {
    color: #2577DA;
}
>>>.statis-div .el-statistic__content {
    color: #2577DA;
    font-size: 30px;
    margin-left: 50px;
    font-weight: 800;
}
</style>
<template>
    <div class="po-detail">
        <h2 disabled mode="h2" class="po-title">工单列表详情</h2>
        <div class="po-content">
            <el-form :inline="true" :model="poDetail" class="form-inline form-first margin-bt" label-position="left"
                label-width="150px">
                <el-form-item label="PO订单号：">{{ poDetail.orderNo }}</el-form-item>
                <el-form-item label="创建时间：">{{ poDetail.createTime }}</el-form-item>
                <el-form-item label="服务商名称：">{{ poDetail.serviceName }}</el-form-item>
                <el-form-item label="需求方联系人信息：">{{ poDetail.demandPersonInfo }}</el-form-item>
                <el-form-item label="服务商接口人信息：">{{ poDetail.servicePersonInfo }}</el-form-item>
                <el-form-item></el-form-item>
                <el-form-item label="服务信息：">{{ poDetail.serviceInfo }}</el-form-item>
                <el-form-item label="专项(服务项)：">{{ poDetail.svrItem }}</el-form-item>
                <el-form-item label="待办工单号：">{{ poDetail.ticketId }}</el-form-item>
                <el-form-item label="腾讯备注信息：">{{ poDetail.memo }}</el-form-item>
                <el-form-item label="园区：">{{ poDetail.campusName }}</el-form-item>
                <el-form-item label="一级机房：">{{ poDetail.idcpName }}</el-form-item>
                <el-form-item label="机房管理单元：">{{ poDetail.idcName }}</el-form-item>
                <el-form-item label="待操作设备数量：">{{ poDetail.num }}</el-form-item>
                <el-form-item label="超时时间：">{{ poDetail.slaTime }}</el-form-item>
            </el-form>
            <div class="table-second margin-bt">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="assetId" label="固资" />
                    <el-table-column prop="sn" label="SN" />
                    <el-table-column prop="rackName" label="机架" />
                    <el-table-column prop="posName" label="机位" />
                    <el-table-column prop="model" label="机型" />
                    <el-table-column prop="svrType" label="设备类型" />
                </el-table>
            </div>
            <div class="form-three margin-bt-double">
                <el-form :model="poDetail" class="form-inline margin-bt" label-position="left">
                    <el-form-item label="是否接单:" prop="isAccepted" >
                        <el-radio-group v-model="poDetail.isAccepted" disabled>
                            <el-radio value="1">是</el-radio>
                            <el-radio value="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="分配工程师:" prop="engineerName" class="form-item-inline">
                        <el-input v-model="poDetail.engineerName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式:" prop="engineerPhone" class="form-item-inline"
                        style="margin-left: 20px;">
                        <el-input v-model="poDetail.engineerPhone" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="acceptedMemo" style="width: 100%">
                        <el-input type="textarea" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-four margin-bt">
                <h3 style="white-space: pre-wrap;">白名单</h3>
                <el-table :data="tableWhiteData" border style="width: 100%">
                    <el-table-column prop="name" label="姓名" />
                    <el-table-column prop="phone" label="联系方式" />
                    <el-table-column prop="IDNum" label="身份证号" />
                </el-table>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from 'vue'
import { get, post } from '../components/https';

const router = useRouter();
const route = useRoute();
const poDetail = ref({})
const gdh = ref('')
const tableData = ref([]), tableWhiteData = ref([])

onMounted(() => {
    gdh.value = route.params.id
    getDataDetail(gdh.value)
})

const getDataDetail = (param) => {
    let Satoken = localStorage.getItem('Satoken')
    const config = {
        'Satoken': Satoken
    }
    get('potickets/getInfo?id=' + param, {}, config).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            let dataJson = data.result
            poDetail.value = dataJson
            tableData.value = JSON.parse(dataJson.deviceInfo)
            tableWhiteData.value = JSON.parse(dataJson.whiteList)
        }
    })
}
</script>
<style scoped>
.po-detail {
    margin: 0 20px;
}

.po-title {
    padding: 0;
    margin: 20px 0;
    box-sizing: border-box;
    white-space: pre-wrap;
    border-bottom-color: rgb(74, 144, 226);
    border-style: solid;
    text-align: center;
    width: auto;

}

.form-first .el-form-item {
    width: 46%;
    margin-bottom: 25px;
}

/deep/.form-inline .el-form-item__label {
    color: #606266;
    font-weight: 700;
}

.margin-bt {
    margin-bottom: 22px;
}

.margin-bt-double {
    margin-bottom: 40px;
}
.table-second, .table-four {
    border-bottom-color: rgb(74, 144, 226);
    border-bottom-style: solid;
}

.form-item-inline {
    display: inline-flex;
}
</style>
<template>
    <div>
        <div class="content-header">
            <div class="span-style" />
            <span>搜索条件</span>
        </div>
        <el-card class="box-card">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="关键字">
                    <el-input v-model="formInline.pgjz"></el-input>
                </el-form-item>
                <el-form-item label="机房">
                    <el-input v-model="formInline.pjf"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="formInline.pzt" filterable allow-create default-first-option placeholder="请选择">
                        <el-option label="状态1" value="1" />
                        <el-option label="状态2" value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="日期">
                    <el-date-picker v-model="formInline.prq" type="date" placeholder="请选择日期" size="default" />
                </el-form-item>
                <el-form-item label="工单类型">
                    <el-select v-model="formInline.pcjlx" placeholder="请选择">
                        <el-option label="服务器退役" value="服务器退役" />
                        <el-option label="服务器搬迁" value="服务器搬迁" />
                        <el-option label="网络故障" value="网络故障" />
                        <el-option label="TPC异常处理" value="TPC异常处理" />
                    </el-select>
                </el-form-item>
                <el-row class="margin-style"><el-col>
                        <el-button @click="onSubmit">自动刷新</el-button>
                        <el-button type="primary" @click="onSubmit">批量催单</el-button>
                        <el-button @click="onSubmit">批量建单</el-button>
                        <el-button type="primary" @click="onSubmit">自动触发建单</el-button>
                        <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                    </el-col></el-row>
            </el-form>
        </el-card>

        <div class="content-header">
            <div class="span-style" />
            <span>工单列表</span>
        </div>
        <el-button @click="exportToExcels('联想自建工单',tableData)">导出</el-button>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id">
            <el-table-column type="selection" width="30" />
            <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop" :label="column.label"
                sortable />
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="detailRow(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
            :page-size="pageSize" layout="prev, pager, next, jumper" :total="totalData"></el-pagination>
        <el-drawer v-model="drawer" title="明细" size="40%">
            <div class="detail-content" v-for="item in columns" :key="item.prop">
                <div class="item-content">
                    <span>{{ item.label }}：</span>
                    <span>{{ rowDetail[item.prop] }}</span>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel } from '../components/exportUtils.js';
import { get, post } from '../components/https';

const exportToExcels = (tableName, rows) => {
    exportToExcel(tableName, rows, columns)
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
const formInline = ref({
    pgjz: '',
    pjf: '',
    pzt: '',
    prq: null,
    pcjlx: ''
})
const columns = [
{
        prop: 'gdh',
        label: '工单号'
    },
    {
        prop: 'gdbt',
        label: '工单标题'
    },
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'dqzt',
        label: '当前状态'
    },
    {
        prop: 'gdzt',
        label: '工单状态'
    },
    {
        prop: 'yjjf',
        label: '一级机房'
    },
    {
        prop: 'dqsj',
        label: '到期时间'
    },
    {
        prop: 'jdsj',
        label: '结单时间'
    }
]
const drawer = ref(false)
const rowDetail = ref()

const detailRow = (row) => {
    drawer.value = true
    rowDetail.value = row
}

const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

const tableData = ref([])
onMounted(() => {
    getTicketList(currentPage.value)
})
const getTicketList = (currentPage) => {
    let Satoken = localStorage.getItem('Satoken')
    const config = {
        'Satoken': Satoken
    }
    get('ticket/list?currentPage=' + currentPage, {}, config).then(data => {
        if (data.errorCode === 0) {
            tableData.value = []
            totalData.value = 0
        }
        
    })
}

</script>


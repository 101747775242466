<template>
    <div class="classStyl">
        <span>当前用户没有权限</span>
    </div>
</template>

<script setup>

</script>
<style scoped>
.classStyl {
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
}
</style>
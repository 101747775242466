<template>
    <div class="work-management">
        <div class="content-header">
            <div class="span-style" />
            <span>考勤数据</span>
        </div>
        <div class="box-card">
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-card class="statis-card">
                        <el-statistic title="项目人数" :value="120" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card class="statis-card">
                        <el-statistic title="在岗人数" :value="110" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card class="statis-card">
                        <el-statistic title="休假人数" :value="10" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card class="statis-card">
                        <el-statistic title="加班人数" :value="16" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card class="statis-card">
                        <el-statistic title="出差人数" :value="2" class="statis-div" />
                    </el-card>
                </el-col>
            </el-row>
       
        </div>
        <div class="content-header">
            <div class="span-style" />
            <span>考勤操作</span>
        </div>
        <el-card class="box-card">
            <el-button>加班申请</el-button>
            <el-button>请假申请</el-button>
        </el-card>

        <div class="table-div">
            <div class="content-header">
                <div class="span-style" />
                <span>搜索条件</span>
            </div>
            <el-card class="box-card">
                <el-form :inline="true" :model="formInline" class="form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.gjz"></el-input>
                    </el-form-item>

                    <el-row class="margin-style"><el-col>
                            <el-button @click="onRest">重置</el-button>
                            <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                        </el-col></el-row>
                </el-form>
            </el-card>

            <div class="content-header">
                <div class="span-style" />
                <span>工单列表</span>
            </div>
            <el-button @click="exportToExcels('考勤管理', tableData)">导出</el-button>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id" @row-dblclick="handleRowDblclick">
            <el-table-column type="selection" width="30" />
            <el-table-column prop="name" label="姓名" sortable>
            </el-table-column>
            <el-table-column prop="sfzh" label="身份证" sortable>
            </el-table-column>
            <el-table-column prop="phone" label="联系方式" sortable>
            </el-table-column>
            <el-table-column prop="station" label="岗位" sortable>
            </el-table-column>
            <el-table-column prop="room" label="一级机房" sortable>
            </el-table-column>
            <el-table-column prop="state" label="状态" sortable>
            </el-table-column>
            <el-table-column prop="p_type" label="班次" sortable>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../../components/exportUtils.js';
import { get, post } from '../../components/https';
import { useRouter } from 'vue-router';

const router = useRouter();

const formInline = ref({
    gjz: '',
})
const tableData = ref([])
onMounted(() => {
    getTicketList(currentPage.value)
})
const onRest = () => {
    formInline.value = {
        gjz: ''
    }
}
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}
// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
// 当分页数据改变时
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const getTicketList = (currentPage) => {
    let queryString = objectToQueryString(formInline.value);
    let Satoken = localStorage.getItem('Satoken')
    const config = {
        'Satoken': Satoken
    }
    get('user/list?currentPage=' + currentPage + '&' +'pageSize='+ pageSize.value +'&'+ queryString, {}, config).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content
            totalData.value = data.result.totalElements
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
        }

    })
}
// 导出表格数据
const exportToExcels = (tableName, rows) => {
    // if (!dateArr.value) {
    //     delete formInline.value.startDate
    //     delete formInline.value.endDate
    // }
    let queryString = objectToQueryString(formInline.value);
    const url = 'https://www.aiyuservice.com/api/user/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            return response.blob();
        }).then(blob => {
            // 创建一个指向该Blob的URL  
            const blobUrl = window.URL.createObjectURL(blob);
            // 创建一个隐藏的<a>标签来触发下载  
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            // 清理：从DOM中移除<a>标签，并释放blob URL  
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}

const handleRowDblclick = (row) => {
    router.push({ name: 'Detail', params: { id: row.gdh } });
}
</script>

<style scoped>
.el-col {
    margin-bottom: 20px;
}
.statis-card {
    background: #F5F9FF;
}
</style>